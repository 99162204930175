/**
 * List Schema of Exchange
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {Exchange} from '@/model/resource/Exchange'

/* TODO: review generated schema */
export class ListExchangeSchema extends DefaultSchema {
  readonly name = 'ListExchange'

  readonly fieldSet: FieldSet<Exchange> = {
    order: (): FieldComponent => ({
      is: Component.Render,
    }),
    imageUrl: (schema): FieldComponent => ({
      is: Component.RenderImage,
      bind: {
        src: schema.model.imageUrl,
        alt: this.translateFrom(schema.fieldName),
        innerClass: 'w-32',
      },
    }),
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
    websiteUrl: (): FieldComponent => ({
      is: Component.Render,
    }),
    blockchainVersionTitles: (): FieldComponent => ({
      is: Component.Render,
    }),
    supportsNeo: (): FieldComponent => ({
      is: Component.Render,
    }),
    supportsGas: (): FieldComponent => ({
      is: Component.Render,
    }),
    supportsNep17: (): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
