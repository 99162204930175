


























































































































import {Component, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import {Exchange} from '@/model/resource/Exchange'
import FilterToggle from '@/components/FilterToggle.vue'
import {ExchangeCollection} from '@/model/collection/ExchangeCollection'
import {ListExchangeSchema} from '@/schema/resource/Exchange/ListExchangeSchema'
import FilterExchange from '@/components/filters/FilterExchange.vue'

@Component({
  components: {FilterToggle, FilterExchange},
})
export default class ListExchangeView extends Mixins(MixinRouteMatch) {
  schema = new ListExchangeSchema()
  collection = new ExchangeCollection()
  filterOpen = false
  activatedName: string | null = null

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    this.collection.setOrderBy('order')
    await this.populate()
  }

  isActivated(name: string) {
    return name === this.activatedName
  }

  desactivated() {
    this.activatedName = null
  }

  @Watch('collection', {deep: true})
  collectionEvent() {
    this.updateRouteFromObject(this.collection)
  }

  goToPersistView(item: Exchange) {
    this.$nav.pushByName('editExchange', item.$id)
  }

  async removeItem(item: Exchange) {
    await this.$dialog.remove(item)
    await item.removeExchange()
    await this.collection.queryAsPage()
  }

  async changeOrder(item: Exchange, increment: number) {
    if (item.order === null) item.order = 0
    item.order += increment
    await item.changeOrder()
    this.$toast.success('system.success.persist')
    await this.populate()
  }

  async populate() {
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }
}
